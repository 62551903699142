<template>
  <div id="forgotpassword-view">
    <section class="bg-light ftco-section">
      <div class="d-flex justify-content-center">
        <img
          src="@/assets/site/images/loader.gif"
          v-if="loader === true"
          class="img-fluid  loader-width"
          alt
        />
      </div>
      <div class="container" v-if="loader === false">
        <div class="row flex-lg-row flex-md-row">
          <div class="col-md-5">
            <div style="margin-top: 2rem">
              <div class="card-body bg-dark-gray">
                <h2 class="mb-3 text-black">Reset Password</h2>
                <form>
                  <b-alert
                    show
                    :variant="color"
                    v-if="response_message != null"
                  >
                    {{ response_message }}
                  </b-alert>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="current_password"
                      placeholder="Enter Your Current Password"
                    />
                    <span class="text-danger" v-if="errors.current_password">{{
                      this.errors.current_password
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="new_password"
                      placeholder="Enter Your New Password"
                    />
                    <span class="text-danger" v-if="errors.new_password">{{
                      this.errors.new_password
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="confirm_password"
                      placeholder="Enter Your Confirm Password"
                    />
                    <span class="text-danger" v-if="errors.confirm_password">{{
                      this.errors.confirm_password
                    }}</span>
                  </div>
                  <div class="row mb-1">
                    <!-- /.col -->
                    <div class="col-6 mt-4 mb-2">
                      <button
                        @click="resetPassword"
                        type="button"
                        class="btn btn-primary btn-block px-4"
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                  <!-- /.col -->
                </form>
              </div>
              <!-- /.login-card-body -->
            </div>
          </div>
          <div class="col-md-7">
            <img
              src="@/assets/site/images/login-bg.jpg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordView",
  data() {
    return {
      current_password: null,
      new_password: null,
      confirm_password: null,
      errors: [],
      userData: [],
      response_message: null,
      color: "success",
      loader: false,
    };
  },
  created() {
    this.setUserDatas();
  },
  methods: {
    setUserDatas() {
      var loc_UserData = localStorage.getItem("userData");
      // console.log(loc_UserData);
      this.userData = JSON.parse(loc_UserData);
      this.userData = this.userData.user;
    },
    resetPassword() {
      if (!this.current_password)
        this.errors = {
          ...this.errors,
          current_password: "Current password cannot be empty!",
        };
      else delete this.errors.current_password;

      if (!this.new_password)
        this.errors = {
          ...this.errors,
          new_password: "New password cannot be empty!",
        };
      else delete this.errors.new_password;

      if (!this.confirm_password)
        this.errors = {
          ...this.errors,
          confirm_password: "Confirm password cannot be empty!",
        };
      else delete this.errors.confirm_password;

      if (
        this.errors?.current_password ||
        this.errors?.new_password ||
        this.errors?.confirm_password
      )
        return;

      if (this.new_password !== this.confirm_password) {
        this.errors = {
          ...this.errors,
          confirm_password: "Passwords don't match!",
        };
        return;
      } else delete this.errors.confirm_password;

      if (this.current_password === this.confirm_password) {
        this.$swal.fire(
          "Error!",
          "Current and new password cannot be same!",
          "error"
        );
        return;
      }

      //Save or update User Details
      this.errors = [];
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;

      let apiUrl =
        process.env.VUE_APP_URL + "customer/changePassword/" + this.userData.id;
      let method_type = "post";

      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          currentpassword: this.current_password,
          newpassword: this.new_password,
          confirmpassword: this.confirm_password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.color = "success";
            this.response_message = "Password changed successfully!";
            this.current_password = null;
            this.new_password = null;
            this.confirm_password = null;
          } else if (data.status === false) {
            this.color = "danger";
            this.response_message = data.message;
          } else {
            this.errors = data.errors;
            this.response_message = null;
          }
          this.loader = false;
        })
        .catch(function(err) {
          console.log("Data", "Error : " + err.message, "error");
        });
    },
  },
};
</script>
